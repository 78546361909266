import React, { useEffect, useRef, useState } from 'react';
import GenericModal from '~/components/GenericModal/GenericModal';
import { uploadAndParseCsvForRentRoll } from '~/api/resident';
import { useFullScreenLoader } from '~/hooks/useFullScreenLoader';
import { toast } from 'react-toastify';
import { ReactComponent as FileUploadIcon } from '~/utils/images/file-upload-icon.svg';
import classes from './UploadRentRoll.module.css';
import { AxiosError } from 'axios';

type UploadRentRollCsvProps = {
  onUpload: (rows: any) => void;
  isVisible: boolean;
  onClose: () => void;
  title: string;
};

const UploadRentRollCsv = (props: UploadRentRollCsvProps) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const { loader } = useFullScreenLoader();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      setSelectedFile(file);
    }
  };

  const uploadFile = () => {
    const form = new FormData();

    if (!selectedFile) {
      return toast.error('Please select a file to upload first');
    }

    form.append('file', selectedFile, 'file');

    loader.show();

    uploadAndParseCsvForRentRoll(form)
      .then((res: any) => {
        props.onUpload(res.data);
      })
      .catch((e) => {
        const error = e as AxiosError;
        toast.error(error.message);
      })
      .finally(() => {
        setSelectedFile(undefined);
        loader.hide();
      });
  };

  useEffect(() => {
    if (props.isVisible) {
      setSelectedFile(undefined);
    }
  }, [props.isVisible]);

  return (
    <GenericModal
      showModal={props.isVisible}
      onChange={props.onClose}
      title={props.title}
      content={
        <div
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          className={`flex justify-center align-center ${classes.fileUploadWrapper}`}
        >
          <FileUploadIcon />
          <p className='margin-top-32' style={{ fontSize: '18px' }}>
            {selectedFile?.name ? (
              selectedFile.name
            ) : (
              <div>
                Drop your file here or{' '}
                <span
                  style={{ textDecoration: 'underline', fontSize: '18px' }}
                  onClick={() => inputRef.current?.click()}
                >
                  browse
                </span>
                .
              </div>
            )}
          </p>
          <input
            type='file'
            accept='.csv,.xlxs,.xls,.xlsx'
            ref={inputRef}
            className={classes.sheetFileinput}
            onChange={(e) => setSelectedFile(e?.target?.files![0])}
          />
        </div>
      }
      actionText='Save'
      actionFunction={uploadFile}
    ></GenericModal>
  );
};

export default UploadRentRollCsv;