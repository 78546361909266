import React from 'react';
import { Column } from 'react-table';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';

import CobuTable from '../CobuTable/CobuTable';
import { ReactComponent as Community } from '~/utils/images/community.svg';
import { ResidentWithReviewInvitationType } from '~/types/resident';
import { useSelector } from 'react-redux';
import { buildingSelector } from '~/redux/selectors';
import { RootState } from '~/redux/store';
import { Mixpanel } from '~/services/MixpanelService';
import UsernameDisplay from '../UsernameDisplay/UsernameDisplay';

interface IProps {
  data: ResidentWithReviewInvitationType[];
  openResidentRemoveModal: (value: string) => void;
  openReviewReceived: (value: string) => void;
}

const ResidentsWithReviewInvitationTable = (props: IProps) => {
  const { buildingId } = useParams<{ buildingId: string }>();
  const building = useSelector((state: RootState) =>
    buildingSelector(state, buildingId)
  );
  const history = useHistory();
  const { data, openResidentRemoveModal, openReviewReceived } = props;
  const tableData = React.useMemo(() => data, [data]);
  const invitationSentColumns = React.useMemo(
    () =>
      [
        {
          Header: 'USER NAME',
          accessor: (row: ResidentWithReviewInvitationType) => {
            return (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <UsernameDisplay
                  firstName={row.user.firstName}
                  lastName={row.user.lastName}
                  email={row.user.email}
                />
              </div>
            );
          }
        },
        {
          Header: 'INVITATION TYPE',
          id: 'TYPE',
          accessor: (row: ResidentWithReviewInvitationType) => {
            return (
              <div  >
                {row.invitationType}
              </div>
            );
          }
        },        
        {
          Header: 'SENT',
          id: 'SENT',
          accessor: (row: ResidentWithReviewInvitationType) => {
            return (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {moment
                  .parseZone(row.invitationTime)
                  .format('MM/DD/YYYY - hh:mm A')}
              </div>
            );
          }
        },
        {
          id: 'remove',
          accessor: (row: ResidentWithReviewInvitationType) => {
            return (
              <div
                className={`table-button pointer`}
                onClick={() => {
                  Mixpanel.track('review_invitee_removed', {
                    email: row.user.email,
                    firstName: row.user.firstName,
                    lastName: row.user.lastName,
                    ...(building && { buildingName: building.name })
                  });
                  openResidentRemoveModal(row.user.uuid);
                }}
              >
                REMOVE
              </div>
            );
          }
        },
        {
          id: 'review-received',
          accessor: (row: ResidentWithReviewInvitationType) => {
            return (
              <div
                className={`table-button pointer`}
                onClick={() => {
                  openReviewReceived(row.uuid);
                }}
              >
                REVIEW RECEIVED
              </div>
            );
          }
        }
      ] as Column<ResidentWithReviewInvitationType>[],
    [history, data]
  );
  return (
    <div>
      {tableData.length > 0 && (
        <div style={{ display: 'flex' }}>
          <CobuTable
            columns={[...invitationSentColumns]}
            data={tableData}
            sort={{ id: 'SENT', descending: false }}
            hideRightBorder={false}
          />
        </div>
      )}
      {tableData.length <= 0 && (
        <div className='noData'>
          <Community />
          <h2>No invitations sent in past 35 days</h2>
        </div>
      )}
    </div>
  );
};

export default ResidentsWithReviewInvitationTable;
