import React, { useEffect, useMemo, useState } from 'react';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import CobuTableAdvanced from '~/components/CobuTableAdvanced/CobuTableAdvanced';
import styles from './index.module.css';
import Spacer from '~/components/Spacer/Spacer';
import { Link, useHistory, useParams } from 'react-router-dom';
import { withFullScreenLoader } from '~/hocs/withFullScreenLoader';
import { useFullScreenLoader } from '~/hooks/useFullScreenLoader';
import { ApiState, ViewState } from '~/types/common';
import {
  EntrataIntegratedBuilding,
  addNewEntrataIntegration,
  deleteEntrataBuildingIntegration,
  getEntrataIntegratedBuildings,
  getEntrataLeadList,
  syncPmsIntegrationRentroll,
  updateBuildingEntrataIntegration
} from '~/api/buildingEntrataIntegration';
import { buildingEntrataIntegrationTableColumns } from './entrataIntegrationsTableColumns';
import './style.css';
import GenericModal from '~/components/GenericModal/GenericModal';
import AddEntrataIntegrationForm, {
  AddNewEntrataIntegrationFormValues
} from './NewEntrataIntegrationForm';
import { useCobuSelector } from '~/hooks/useCobuSelector';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { Tabs } from '~/components/Tabs';
import {
  YardiBuildingIntegration,
  YardiProperty,
  addNewYardiIntegration,
  getYardiIntegratedBuildings,
  fetchYardiProperties,
  getYardiRentroll,
  fetchYardiCredentials,
  YardiCredential,
  deleteYardiBuildingIntegration,
  updateBuildingYardiIntegration
} from '~/api/yardiIntegration';
import { yardiBuildingIntegrationTableColumns } from './yardiIntegrationsTableColumns';
import AddYardiIntegrationForm, {
  AddNewYardiIntegrationFormValues
} from './NewYardiIntegrationForm';
import { DeactivationLogUser, fetchPmsSyncDeactivationLogs } from '~/api/pms';
import { getBuildingFromStore } from '~/helpers/reduxStoreHelpers';
import { useSelector } from 'react-redux';
import { Roles } from '~/enums/Roles';
import { RootState } from '~/redux/store';
import NotFound from '~/components/NotFound/NotFound';
import { ReactComponent as CopyIcon } from '~/utils/images/copyIcon.svg';
import moment from 'moment';

type IntegrationTypes = 'entrata' | 'yardi';

const PmsIntegrationContainer = () => {
  const { loader } = useFullScreenLoader();
  const [yardiCredentials, setyardiCredentials] = useState<YardiCredential[]>(
    []
  );
  const [getBuildingsApiState, setgetBuildingsApiState] =
    useState<ApiState>('LOADING');
  const [integrations, setIntegrations] = useState<EntrataIntegratedBuilding[]>(
    []
  );
  const [buildingIntegrationsBeingSynced, setbuildingIntegrationsBeingSynced] =
    useState<any>({});
  const [yardiIntegrations, setyardiIntegrations] = useState<
    YardiBuildingIntegration[]
  >([]);
  const [yardiProperties, setyardiProperties] = useState<YardiProperty[]>([]);
  const [addIntegrationViewState, setaddIntegrationViewState] =
    useState<ViewState>('HIDDEN');
  const [selectedIntegrationType, setselectedIntegrationType] =
    useState<IntegrationTypes>('entrata');
  const history = useHistory();
  const [deactivationLogs, setdeactivationLogs] = useState<
    DeactivationLogUser[] | undefined
  >();
  const { buildingId } = useParams<{ buildingId: string }>();
  const selectedBuilding = useMemo(
    () => getBuildingFromStore(buildingId),
    [buildingId]
  );

  const [
    selectedEntrataIntegrationToEdit,
    setselectedEntrataIntegrationToEdit
  ] = useState<EntrataIntegratedBuilding>();

  const [selectedYardiIntegrationToEdit, setselectedYardiIntegrationToEdit] =
    useState<YardiBuildingIntegration>();

  const [getYardiPropertiesApiState, setgetYardiPropertiesApiState] =
    useState<ApiState>('IDLE');
  const [getYardiCredentialsApiState, setgetYardiCredentialsApiState] =
    useState<ApiState>('IDLE');
  const [getYardiIntegrationsApiState, setgetYardiIntegrationsApiState] =
    useState<ApiState>('IDLE');

  const { buildings } = useCobuSelector((state) => ({
    buildings: state.building.buildings
  }));

  const { userRole } = useSelector((state: RootState) => ({
    userRole: state.auth.userRole
  }));

  const isSuperAdminOrVcm = userRole == Roles.Superadmin || Roles.Vcm;

  /* UI Components */

  const downloadEntrataCsv = (buildingId: string) => {
    loader.show();
    getEntrataLeadList(buildingId).finally(() => {
      loader.hide();
    });
  };

  const downloadYardiCsv = (buildingId: string) => {
    loader.show();
    getYardiRentroll(buildingId).finally(() => loader.hide());
  };

  const getEntrataIntegrations = async () => {
    return getEntrataIntegratedBuildings().then((res) => {
      setgetBuildingsApiState('COMPLETED');
      if (buildingId) {
        setIntegrations(
          res.data
            .sort((a, b) => b?.buildingName?.localeCompare(a.buildingName))
            .filter((integration) => integration.buildingId == buildingId)
        );
      } else {
        setIntegrations(
          res.data.sort((a, b) =>
            b?.buildingName?.localeCompare(a.buildingName)
          )
        );
      }
    });
  };

  const getPropertyListFromYardi = () => {
    setgetYardiPropertiesApiState('LOADING');
    fetchYardiProperties()
      .then((res) =>
        setyardiProperties(
          res.data.properties.sort((a, b) => {
            return b?.MarketingName?.toString().localeCompare(
              a?.MarketingName?.toString()
            );
          })
        )
      )
      .finally(() => {
        setgetYardiPropertiesApiState('COMPLETED');
      });
  };

  const getYardiIntegrations = () => {
    setgetYardiIntegrationsApiState('LOADING');
    getYardiIntegratedBuildings()
      .then((res) => {
        if (buildingId) {
          setyardiIntegrations(
            res.data.integrations
              ?.sort((a, b) => b?.buildingName?.localeCompare(a.buildingName))
              .filter((integration) => integration.buildingId == buildingId)
          );
        } else {
          setyardiIntegrations(
            res.data.integrations.sort((a, b) =>
              b.buildingName.localeCompare(a.buildingName)
            )
          );
        }
      })
      .finally(() => setgetYardiIntegrationsApiState('COMPLETED'));
  };

  const openAddIntegrationModal = () => setaddIntegrationViewState('VISIBLE');
  const closeAddIntegrationModal = () => {
    setselectedEntrataIntegrationToEdit(undefined);
    setselectedYardiIntegrationToEdit(undefined);
    setaddIntegrationViewState('HIDDEN');
  };

  const syncEntrataIntegration = (integration: EntrataIntegratedBuilding) => {
    setbuildingIntegrationsBeingSynced({
      ...buildingIntegrationsBeingSynced,
      [integration.buildingId]: true
    });
    setIntegrations(
      integrations.map((i) => {
        if (i.buildingId == integration.buildingId) {
          i.lastSyncStatus = 'SYNCING';
        }
        return i;
      })
    );
    syncPmsIntegrationRentroll('entrata', {
      buildings: [integration.buildingId]
    }).then((res) => {
      setIntegrations(
        integrations.map((i) => {
          if (i.buildingId == integration.buildingId) {
            i.lastSyncTime = moment().toDate();
            i.lastSyncStatus = 'SYNCING';
          }
          return i;
        })
      );
      setbuildingIntegrationsBeingSynced({
        ...buildingIntegrationsBeingSynced,
        [integration.buildingId]: undefined
      });
      getEntrataIntegrations();
    });
  };

  const syncYardiIntegration = (integration: YardiBuildingIntegration) => {
    setbuildingIntegrationsBeingSynced({
      ...buildingIntegrationsBeingSynced,
      [integration.buildingId]: true
    });
    setyardiIntegrations(
      yardiIntegrations.map((i) => {
        if (i.buildingId == integration.buildingId) {
          i.lastSyncStatus = 'SYNCING';
        }
        return i;
      })
    );
    syncPmsIntegrationRentroll('yardi', {
      buildings: [integration.buildingId]
    }).then((res) => {
      setyardiIntegrations(
        yardiIntegrations.map((i) => {
          if (i.buildingId == integration.buildingId) {
            i.lastSyncTime = moment().toDate();
            i.lastSyncStatus = 'SYNCING';
          }
          return i;
        })
      );
      setbuildingIntegrationsBeingSynced({
        ...buildingIntegrationsBeingSynced,
        [integration.buildingId]: undefined
      });
      getYardiIntegrations();
    });
  };

  const onEditEntrataIntegrationClicked = (
    integration: EntrataIntegratedBuilding
  ) => {
    setselectedEntrataIntegrationToEdit(integration);
    setaddIntegrationViewState('VISIBLE');
  };

  const onEditYardiIntegrationClicked = (
    integration: YardiBuildingIntegration
  ) => {
    setselectedYardiIntegrationToEdit(integration);
    setaddIntegrationViewState('VISIBLE');
  };

  const onDeleteEntrataIntegrationClicked = (integrationId: string) => {
    loader.show();
    deleteEntrataBuildingIntegration(integrationId)
      .then(() => {
        getEntrataIntegrations();
      })
      .finally(() => {
        loader.hide();
      });
  };

  const onDeleteYardiIntegrationClicked = (integrationId: string) => {
    loader.show();
    deleteYardiBuildingIntegration(integrationId)
      .then(() => {
        getYardiIntegrations();
      })
      .finally(() => {
        loader.hide();
      });
  };

  const addEntrataIntegration = (
    values: AddNewEntrataIntegrationFormValues
  ) => {
    loader.show();

    const newIntegration = new FormData();
    newIntegration.append('domain', values.domain);
    newIntegration.append('username', values.username);
    newIntegration.append('password', values.password);
    newIntegration.append('propertyId', values.propertyId as string);
    newIntegration.append('building', values.building as string);
    newIntegration.append('default', values.default.toString());

    if (values.default) {
      newIntegration.append('unmarkOtherDefault', 'true');
    }

    addNewEntrataIntegration(newIntegration)
      .then((res) => {
        closeAddIntegrationModal();
        getEntrataIntegrations();
        toast(res.data?.message);
      })
      .catch((e: any) => {
        const error = e as AxiosError;
        toast.error(
          error.response?.data?.message ||
            'Something went wrong. Please try again later'
        );
      })
      .finally(loader.hide);
  };
  const saveEntrataChanges = (values: AddNewEntrataIntegrationFormValues) => {
    loader.show();

    const integration = integrations?.find(
      (integration) =>
        integration.integrationId ==
        selectedEntrataIntegrationToEdit?.integrationId
    );

    integration!.domain = values.domain;
    integration!.username = values.username;
    integration!.password = values.password;
    integration!.propertyId = values.propertyId;

    updateBuildingEntrataIntegration({
      uuid: selectedEntrataIntegrationToEdit?.integrationId,
      entrata_domain: values.domain,
      entrata_password: values.password,
      entrata_username: values.username,
      entrata_property_id: values.propertyId,
      default: values.default,
      unmarkOtherDefault: true
    })
      .then((res) => {
        closeAddIntegrationModal();
        getEntrataIntegrations();
        toast(res.data?.message);
      })
      .catch((e: any) => {
        const error = e as AxiosError;
        toast.error(
          error.response?.data?.message ||
            'Something went wrong. Please try again later'
        );
      })
      .finally(loader.hide);
  };

  const onSaveEntrataChangesClicked = (
    values: AddNewEntrataIntegrationFormValues
  ) => {
    if (!selectedEntrataIntegrationToEdit) {
      addEntrataIntegration(values);
    } else {
      saveEntrataChanges(values);
    }
  };

  const saveYardiChanges = (values: AddNewYardiIntegrationFormValues) => {
    loader.show();

    updateBuildingYardiIntegration({
      uuid: selectedYardiIntegrationToEdit?.uuid,
      yardi_property_id: values.yardiPropertyId
    })
      .then((res) => {
        closeAddIntegrationModal();
        getYardiIntegrations();
      })
      .finally(() => {
        loader.hide();
      });
  };

  const addYardiIntegration = (values: AddNewYardiIntegrationFormValues) => {
    loader.show();

    const property = yardiProperties.find(
      (prop) => prop.Code == values.yardiPropertyId
    );

    const newIntegration = new FormData();
    newIntegration.append('yardiPropertyId', values.yardiPropertyId as string);
    newIntegration.append('credentials', values.credentials as string);
    newIntegration.append('yardiVersion', property?.yardiVersion as string);
    newIntegration.append('building', values.building as string);

    addNewYardiIntegration(newIntegration)
      .then((res) => {
        closeAddIntegrationModal();
        getYardiIntegrations();
        toast(res.data?.message);
      })
      .catch((e: any) => {
        loader.hide();
        const error = e as AxiosError;
        toast.error(
          error.message || 'Something went wrong. Please try again later'
        );
      });
  };

  const onSaveYardiChangesClicked = (
    values: AddNewYardiIntegrationFormValues
  ) => {
    if (!selectedYardiIntegrationToEdit) {
      addYardiIntegration(values);
    } else {
      saveYardiChanges(values);
    }
  };

  const displayAddIntegrationForm = () => {
    let Component;

    switch (selectedIntegrationType) {
      case 'entrata': {
        if (addIntegrationViewState != 'VISIBLE') {
          Component = null;
        } else {
          Component = (
            <GenericModal
              title={
                selectedEntrataIntegrationToEdit
                  ? 'Edit Entrata Integration'
                  : 'Add Entrata Integration'
              }
              showModal={addIntegrationViewState == 'VISIBLE'}
              onChange={closeAddIntegrationModal}
              content={
                <AddEntrataIntegrationForm
                  buildings={buildings}
                  onFormSubmit={onSaveEntrataChangesClicked}
                  selectedIntegration={selectedEntrataIntegrationToEdit}
                />
              }
              hideActionButton
              canceActionButton={true}
            ></GenericModal>
          );
        }
        break;
      }
      case 'yardi': {
        if (addIntegrationViewState != 'VISIBLE') {
          Component = null;
        } else {
          Component = (
            <GenericModal
              title='Add Yardi Integration'
              showModal={addIntegrationViewState == 'VISIBLE'}
              onChange={closeAddIntegrationModal}
              width='900px'
              content={
                <AddYardiIntegrationForm
                  selectedIntegration={selectedYardiIntegrationToEdit}
                  buildings={buildings}
                  onFormSubmit={onSaveYardiChangesClicked}
                  properties={yardiProperties}
                  credentials={yardiCredentials}
                  entrataIntegrations={integrations}
                  yardiIntegrations={yardiIntegrations}
                  onClose={closeAddIntegrationModal}
                />
              }
              hideActionButton
              canceActionButton={true}
            ></GenericModal>
          );
        }
        break;
      }
    }

    return Component;
  };

  const displayBuildingIntegrationByIntegrationType = () => {
    let Component;

    switch (selectedIntegrationType) {
      case 'yardi':
        Component = (
          <div className='building-integration-table'>
            <CobuTableAdvanced
              columns={yardiBuildingIntegrationTableColumns({
                downloadYardiCsv,
                onEditClicked: onEditYardiIntegrationClicked,
                onDeleteClicked: onDeleteYardiIntegrationClicked,
                onSyncClicked: syncYardiIntegration,
                buildingIntegrationsBeingSynced
              })}
              data={yardiIntegrations}
              noData={{ label: 'No Building Integration Found' }}
              // actions={[createPostAction()]}
              sort={{ id: 'buildingName', descending: true }}
            />
          </div>
        );
        break;
      case 'entrata':
        Component = (
          <div className='building-integration-table'>
            <CobuTableAdvanced
              columns={buildingEntrataIntegrationTableColumns({
                downloadEntrataCsv,
                getDeactivationLogs,
                onSyncClicked: syncEntrataIntegration,
                onEditClicked: onEditEntrataIntegrationClicked,
                onDeleteClicked: onDeleteEntrataIntegrationClicked,
                buildingIntegrationsBeingSynced
              })}
              data={integrations}
              noData={{ label: 'No Building Integration Found' }}
              sort={{ id: 'buildingName', descending: true }}
              // actions={[createPostAction()]}
            />
          </div>
        );
        break;
    }

    return Component;
  };

  const getDeactivationLogs = (integrationId: string) => {
    loader.show();
    fetchPmsSyncDeactivationLogs(integrationId)
      .then((res) => {
        setdeactivationLogs(res.data?.details?.users);
      })
      .finally(() => loader.hide());
  };

  const getYardiCredentials = () => {
    setgetYardiCredentialsApiState('LOADING');
    fetchYardiCredentials()
      .then((res) =>
        setyardiCredentials(
          res.data.sort((a, b) => a.name.localeCompare(b.name))
        )
      )
      .finally(() => setgetYardiCredentialsApiState('COMPLETED'));
  };

  useEffect(() => {
    if (
      [
        getBuildingsApiState,
        getYardiCredentialsApiState,
        getYardiIntegrationsApiState
      ].includes('LOADING')
    ) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [
    getBuildingsApiState,
    getYardiCredentialsApiState,
    getYardiIntegrationsApiState
  ]);

  useEffect(() => {
    if (
      selectedIntegrationType == 'yardi' &&
      addIntegrationViewState == 'VISIBLE' &&
      getYardiPropertiesApiState == 'LOADING'
    ) {
      loader.show();
    } else {
      loader.hide();
    }
  }, [
    selectedIntegrationType,
    addIntegrationViewState,
    getYardiPropertiesApiState
  ]);

  useEffect(() => {
    {
      loader.show();
      getEntrataIntegrations().finally(loader.hide);
    }
    getYardiIntegrations();
    getPropertyListFromYardi();
    getYardiCredentials();
  }, []);

  if (getBuildingsApiState == 'LOADING')
    return (
      <div className={styles.loadingContainer}>
        <h3 style={{ textAlign: 'center', marginTop: '30vh' }}>
          Loading PMS Integrations
        </h3>
      </div>
    );

  if (!isSuperAdminOrVcm) return <NotFound />;

  return (
    <div className='content-container'>
      <Breadcrumbs />
      <Spacer height={20} />
      {displayAddIntegrationForm()}

      <div
        className='font-size-24 color-dark-blue margin-bottom-16'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        {buildingId && selectedBuilding ? (
          <div>
            PMS Integrations for <b>'{selectedBuilding.name}'</b>
          </div>
        ) : (
          <h1 className='text-capitalize'>PMS integrations</h1>
        )}
        <button
          className='btn btn-primary btn-small'
          onClick={openAddIntegrationModal}
        >
          Add New Integration
        </button>
      </div>
      {buildingId && selectedBuilding && (
        <div
          className='margin-bottom-24'
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          {selectedIntegrationType == 'entrata' && (
            <div
              style={{
                display: 'inline-flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                height: '100px',
                cursor: 'pointer',
                gap: '10px 10px',
                maxHeight: '50px'
              }}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.origin}/entrata-config/${buildingId}`
                );

                toast('Link copied!');
              }}
            >
              <div>Entrata PMS config form link</div>
              <CopyIcon width={20} height={20} />
            </div>
          )}

          {selectedIntegrationType == 'yardi' && (
            <div
              style={{
                display: 'inline-flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                height: '100px',
                cursor: 'pointer',
                gap: '10px 10px',
                maxHeight: '50px'
              }}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.origin}/yardi-config/${buildingId}`
                );

                toast('Link copied!');
              }}
            >
              <div>Yardi PMS config form link</div>
              <CopyIcon width={20} height={20} />
            </div>
          )}
        </div>
      )}

      <div className='margin-bottom-16' style={{ width: '300px' }}>
        <Tabs.Container
          onChange={(value) =>
            setselectedIntegrationType(value as IntegrationTypes)
          }
          value={selectedIntegrationType}
        >
          <Tabs.Option
            selected={selectedIntegrationType == 'entrata'}
            key={'entrata'}
            value={'entrata'}
          >
            Entrata
          </Tabs.Option>
          <Tabs.Option
            selected={selectedIntegrationType == 'yardi'}
            key={'yardi'}
            value={'yardi'}
          >
            Yardi
          </Tabs.Option>
        </Tabs.Container>
      </div>
      {selectedIntegrationType == 'yardi' && (
        <div className='margin-top-8'>
          <Link to={'/pms-integrations/yardi-credentials'}>
            Configure Yardi Credentials
          </Link>
        </div>
      )}
      <Spacer height={20} />
      {displayBuildingIntegrationByIntegrationType()}
      <GenericModal
        title={'Logs'}
        dismissOnTapOutside
        hideActionButton
        width='900px'
        onChange={() => setdeactivationLogs(undefined)}
        cancelOverride='Close'
        content={
          <div>
            <h4>Deactivated Users</h4>
            <CobuTableAdvanced
              data={deactivationLogs ?? []}
              columns={[
                { Header: 'UUID', accessor: 'uuid' },
                { Header: 'Name', accessor: 'email' },
                { Header: 'Phone', accessor: 'phone' }
              ]}
              noData={{ label: 'No deactivated users found' }}
            ></CobuTableAdvanced>
          </div>
        }
        showModal={!!deactivationLogs}
      ></GenericModal>
    </div>
  );
};

export default withFullScreenLoader(PmsIntegrationContainer);
