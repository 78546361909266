import { AssignmentTagAccessor, IAssignmentTag } from '~/types/assets';

export enum ASSIGNMENT_TAGS {
  all = 'All',
  unassigned = 'Unassigned'
}

export const defaultTagAssignments: IAssignmentTag[] = [
  {
    uuid: '1',
    [AssignmentTagAccessor.tagName]: ASSIGNMENT_TAGS.all,
    [AssignmentTagAccessor.TagUuid]: '',
    [AssignmentTagAccessor.FirstName]: '',
    [AssignmentTagAccessor.Email]: '',
    [AssignmentTagAccessor.JobTitle]: '',
    [AssignmentTagAccessor.CalendarLink]: ''
  },
  {
    uuid: '2',
    [AssignmentTagAccessor.tagName]: ASSIGNMENT_TAGS.unassigned,
    [AssignmentTagAccessor.TagUuid]: '',
    [AssignmentTagAccessor.FirstName]: '',
    [AssignmentTagAccessor.Email]: '',
    [AssignmentTagAccessor.JobTitle]: '',
    [AssignmentTagAccessor.CalendarLink]: ''
  }
];
