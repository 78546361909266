import React, { useEffect } from 'react';
import { ColumnGrowTable } from '~/components/ColumnGrowTable/ColumnGrowTable';
import {
  ITagAssignmentTableData,
  usePropertyAssignment
} from '~/hooks/assets/usePropertyAssignment';
import './PropertyAssignment.css';
import Spinner from '~/components/Spinner/Spinner';
import GenericModal from '~/components/GenericModal/GenericModal';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import Spacer from '~/components/Spacer/Spacer';
import { FormInputConfig } from '~/types/residentManagement';
import { FormField } from '~/components/FormField';
import { useForm } from 'react-hook-form';
import { IAssignmentTag } from '~/types/assets';

export const PropertyAssignment = () => {
  const {
    tableData,
    columnConfig,
    loading,
    showModal,
    closeModal,
    onCreateTag,
    inputRef,
    onUploadAssignmentCSV,
    onSubmitAssignmentCSV,
    showAttributonForm,
    submitTagAttribute,
    tagAttributeformConfig,
    tagAttributes,
    uploadedFile
  } = usePropertyAssignment();

  const { register, handleSubmit, errors, control, reset } =
    useForm<IAssignmentTag>({
      defaultValues: {}
    });

  useEffect(() => {
    reset(tagAttributes);
  }, [tagAttributes]);

  const generateField = (fieldConfig: FormInputConfig) => {
    return (
      <div
        key={fieldConfig.id}
        style={fieldConfig.displayNone ? { display: 'none' } : {}}
      >
        <div className='item'>
          {!!fieldConfig?.label && (
            <label
              style={fieldConfig?.styles?.lableStyles}
              className={`${fieldConfig?.styles?.lableClassName}`}
              htmlFor='title'
            >
              {fieldConfig.label}
            </label>
          )}
          <FormField
            fieldConfig={fieldConfig}
            errors={errors}
            register={register}
            control={control}
          />
        </div>
      </div>
    );
  };

  return (
    <div className='property-container float-spinner-parent'>
      <Breadcrumbs />
      <Spacer height={20} />
      {loading && <Spinner float />}
      <div className='main-container'>
        <div className='single-assignment'>
          <div className='heading4 semi-bold'>
            Add Tag And Change/Delete Assignment
          </div>
          <Spacer height={10} />
          <ColumnGrowTable<ITagAssignmentTableData>
            columns={columnConfig}
            tableData={tableData}
            height={window.screen.height * 0.5}
          />
        </div>
        <div className='bulk-assignment'>
          <div className='heading4 semi-bold'>Bulk Assignment</div>
          <Spacer height={10} />
          <div>
            Please ensure csv has 2 columns "Property Name" and "New Assignee
            Name"
          </div>
          <Spacer height={12} />
          <div className='csv-container'>
            <div>
              <label
                htmlFor='file-upload'
                style={{ display: 'block', minHeight: 0, borderRadius: 4 }}
                className='btn-standard btn-secondary cursor-pointer'
              >
                Upload CSV
              </label>
              <input
                id='file-upload'
                type='file'
                accept='.csv'
                onChange={onUploadAssignmentCSV}
              />
            </div>
            {uploadedFile && (
              <div
                style={{ minHeight: 0, borderRadius: 4 }}
                onClick={onSubmitAssignmentCSV}
                className='btn-standard btn-primary cursor-pointer'
              >{`Submit CSV: ${uploadedFile.name}`}</div>
            )}
          </div>
        </div>
        {showAttributonForm && (
          <div className='tag-attributes'>
            <div className='heading4 semi-bold'>Tag Attribution</div>
            <form
              id='form-container'
              onSubmit={handleSubmit(submitTagAttribute)}
            >
              <div className='grid'>
                {tagAttributeformConfig.map((field) => generateField(field))}
              </div>
              <div className='formButtons'>
                <button
                  className='btn-standard btn-primary margin-left-16 '
                  type='submit'
                >
                  {'Save'}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
      {showModal && (
        <GenericModal
          showModal={showModal}
          title={'Add Tag Name'}
          content={
            <input
              className='tag-input'
              type='text'
              placeholder={'E.g. Melissa'}
              ref={inputRef}
            />
          }
          actionText={'Create Tag'}
          onChange={closeModal}
          actionButtonDisabled={false}
          actionFunction={onCreateTag}
        />
      )}
    </div>
  );
};
