import React from 'react';
import { Column } from 'react-table';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import CobuTable from '../CobuTable/CobuTable';
import { ReactComponent as NoReview } from '~/utils/images/no-review.svg';
import { ReviewReceivedType } from '~/types/reviewReceived';
import { SearchReviewResultsType } from '~/types/reviewReceived';
import { ReactComponent as Pencil } from '~/utils/images/pencil.svg';
import { ReactComponent as Link } from '~/utils/images/link.svg';
import RatingDisplay from '../RatingDisplay/RatingDisplay';
import UsernameDisplay from '../UsernameDisplay/UsernameDisplay';
import { Roles } from '~/enums/Roles';
interface IProps {
  data: SearchReviewResultsType[];
  removeAssignment: (row: SearchReviewResultsType) => void;
  userRole: string;
}

const AllReviewsTable = (props: IProps) => {
  const history = useHistory();
  const { data, userRole, removeAssignment } = props;
  const tableData = React.useMemo(() => data, [data]);

  const invitationType = {
    Header: 'INVITATION TYPE',
    id: 'invitation-type',
    show: false,
    accessor: (row: SearchReviewResultsType) => {
      return (
        <div>
          {row.invitation_type}
        </div>
      );
    }
  }

  const reviewReceivedColumns = React.useMemo(
    () =>
      [
        {
          Header: 'NAME ON REVIEW',
          id: 'name-on-review',
          accessor: (row: SearchReviewResultsType) => {
            return (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {row.contributor_name}
              </div>
            );
          }
        },
        {
          Header: 'Is Cobu Review',
          id: 'cobu_review',
          accessor: (row: SearchReviewResultsType) => {
            return (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}

              >
                {(row.cobu_review) ? 'YES' : 'NO'}

              </div>
            );
          }
        },
        {
          Header: 'Cobu User',
          id: 'cobu_user',
          accessor: (row: SearchReviewResultsType) => {
            return (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}

              >
                {(row.cobu_review) ? row.firstName + " " + row.lastName : '--'}
              </div>
            );
          }
        },
        {
          Header: 'RATING',
          id: 'rating',
          accessor: (row: SearchReviewResultsType) => {
            return (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                <RatingDisplay value={row.review_rating} small />
              </div>
            );
          }
        },
        {
          Header: 'DATE OF REVIEW',
          id: 'date-of-review',
          accessor: (row: SearchReviewResultsType) => {
            return (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {moment.parseZone(row.date_found).format('MM/DD/YYYY')}
              </div>
            );
          }
        },


        {
          Header: 'REVIEW TEXT',
          id: 'review-text',
          accessor: (row: SearchReviewResultsType) => {
            return (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  cursor: 'pointer'
                }}
              >
                {row.review_snippet}
              </div>
            );
          }
        },
        {
          Header: 'Has Been Reviewed',
          id: 'has-been-reviewed',
          accessor: (row: SearchReviewResultsType) => {
            return (
              <div>

                {(row.has_been_reviewed) ? 'YES' : 'No'}
              </div>
            );
          }
        },
        {
          id: 'unassign',
          accessor: (row: SearchReviewResultsType) => {
            return userRole !== Roles.Pm ? (
              <div
                className={`table-button pointer`}
                onClick={() => {
                  removeAssignment(row);
                }}
              >
                  REMOVE ASSIGNMENT
              </div>
            ) : null;
          }
        }
      ] as Column<SearchReviewResultsType>[],
    [history, data]
  );
  return (
    <div>
      {tableData.length > 0 && (
        <div style={{ display: 'flex' }}>
          <CobuTable
            //@ts-ignore
            columns={
              //@ts-ignore
              userRole === Roles.Pm
                ? [...reviewReceivedColumns]
                : [
                  ...reviewReceivedColumns.slice(0, 4), // Elements before the index
                  invitationType,                           // New item to insert
                  ...reviewReceivedColumns.slice(4),    // Elements after the index
                ]
            }
            data={tableData}
            sort={{ id: 'date-of-review', descending: true }}
            hideRightBorder={false}
          />
        </div>
      )}
      {tableData.length <= 0 && (
        <div className='noData'>
          <NoReview />
          <h2>
            There are no reviews based on Cobu’s outreach, yet. Please check back often.
          </h2>
        </div>
      )}
    </div>
  );
};

export default AllReviewsTable;
